<template>
  <div>
    <generic-sub-classifier
      v-if="
        $route.params.subClassifierId !== '44' &&
        $route.params.subClassifierId !== '24'
      "
    ></generic-sub-classifier>
    <economic-activity-sub-classifier
      v-else-if="$route.params.subClassifierId === '44'"
    ></economic-activity-sub-classifier>
    <areas-sub-classifier
      v-else-if="$route.params.subClassifierId === '24'"
    ></areas-sub-classifier>
  </div>
</template>

<script>
import GenericSubClassifier from "@/components/admin/subclassifiers/genericSubClassifier";
import EconomicActivitySubClassifier from "@/components/admin/subclassifiers/economicActivitySubClassifier";
import AreasSubClassifier from "@/components/admin/subclassifiers/areasSubClassifier";
export default {
  name: "genericSubClassifierView",
  components: {
    AreasSubClassifier,
    EconomicActivitySubClassifier,
    GenericSubClassifier,
  },
};
</script>

<style scoped></style>
