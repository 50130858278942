<template>
  <div>
    <h2>Справочники видов экономической деятельности ПСС-44</h2>
    <el-table :data="economyActivities" style="width: 100%">
      <el-table-column type="expand">
        <template v-if="props.row.childList.length !== 0" slot-scope="props">
          <el-table :data="props.row.childList" style="width: 100%">
            <el-table-column type="expand">
              <template
                v-if="props2.row.childList.length !== 0"
                slot-scope="props2"
              >
                <el-table :data="props2.row.childList" style="width: 100%">
                  <el-table-column
                    width="100"
                    label="Код группировки"
                    prop="code"
                  >
                  </el-table-column>
                  <el-table-column
                    label="Наименование группировки и ее описание"
                    prop="activityName"
                  >
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column width="100" label="Подсекция" prop="code">
            </el-table-column>
            <el-table-column
              label="Наименование группировки и ее описание"
              prop="activityName"
            >
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column width="70" label="секция" prop="code"> </el-table-column>
      <el-table-column
        label="Наименование группировки и ее описание"
        prop="activityName"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "economicActivitySubClassifier",
  data() {
    return {
      oneLevel: [],
      tmp: [],
      tableData: [{ section: "A", name: "test" }],
    };
  },

  computed: {
    ...mapGetters({
      economyActivities: "GETLISTECONOMYACTIVITY",
    }),
  },

  methods: {
    getEconomicActivityList() {
      this.$store.dispatch("getListEconomyActivitySubClassifier");
    },
  },

  async mounted() {
    await this.getEconomicActivityList();
  },
};
</script>

<style scoped>
.tree {
  padding-bottom: 10px;
}
</style>
