<template>
  <div>
    <h2>Подсправочник</h2>
    <el-button @click="addSubClassifier" type="primary">Добавить</el-button>
    <el-table
      v-loading="loadData"
      :data="
        subClassifierData.content.filter(
          (data) =>
            !search || data.name.toLowerCase().includes(search.toLowerCase())
        )
      "
    >
      <el-table-column label="Наименование" prop="name"></el-table-column>
      <el-table-column label="">
        <template slot-scope="raw">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="float: right"
                @click="openEditModal(raw.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                size="mini"
                @click="deleteItem(raw.row)"
                style="float: right; margin-right: 10px"
                type="danger"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <custom-modal
      :title="'Подсправочник'"
      :visible="showModal === 'createSubClassifier'"
    >
      <el-form :model="item" label-position="top" label-width="120px">
        <el-form-item prop="name" label="Наименование">
          <el-input v-model="item.name"></el-input>
        </el-form-item>
        <el-button type="primary" @click="addItem">Добавить</el-button>
      </el-form>
    </custom-modal>

    <custom-modal
      :title="'Подсправочник'"
      :visible="showModal === 'updateSubClassifier'"
    >
      <el-form :model="item" label-position="top" label-width="120px">
        <el-form-item prop="name" label="Наименование">
          <el-input v-model="item.name"></el-input>
        </el-form-item>
        <el-button type="primary" @click="editItem">Изменить</el-button>
      </el-form>
    </custom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";
import CustomModal from "@/components/customModal";

export default {
  name: "genericSubClassifier",
  components: { CustomModal },
  mixins: [notification],
  data() {
    return {
      loadData: true,
      addItemModalShow: false,
      editItemModalShow: false,
      search: "",
      item: {
        id: 0,
        name: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      subClassifierData: "GETSUBCLASSIFIERDATA",
      showModal: "GETSHOWMODAL",
    }),

    subClassifierIdFromPath: {
      get() {
        return this.$route.params.subClassifierId;
      },
    },
  },

  methods: {
    addSubClassifier() {
      this.$store.dispatch("setShowModal", "createSubClassifier");
    },

    openEditModal(item) {
      this.$store.dispatch("setShowModal", "updateSubClassifier");
      this.item = item;
    },

    editItem() {
      let data = {
        subClassifierId: this.subClassifierIdFromPath,
        subClassifier: this.item,
      };
      this.$store.dispatch("updateSubClassifier", data).then((response) => {
        console.log(response);
        this.notification("Успешно", "Элемент изменен", "success");
        this.$store.dispatch("setShowModal", null);
        this.getSubClassifierById({
          page: 0,
          size: 20,
        });
      });
    },

    deleteItem(item) {
      console.log(item);
      this.confirm(
        "Удалить элемент?",
        "Вы действительно хотите удалить элемент подсправочники",
        "warning",
        "Удалить",
        "Отмена"
      )
        .then(() => {
          this.$store
            .dispatch("deleteSubClassifier", {
              subClassifierId: this.subClassifierIdFromPath,
              itemId: item.id,
            })
            .then(() => {
              this.notification("Успешно", "Пользователь удален", "success");
              this.getSubClassifierById({
                page: 0,
                size: 20,
              });
            });
        })
        .catch(() => {
          console.log("2");
        });
    },

    addItem() {
      let data = {
        subClassifierId: this.subClassifierIdFromPath,
        subClassifier: this.item,
      };
      this.$store.dispatch("createSubClassifier", data).then((response) => {
        console.log(response);
        this.notification("Успешно", "Элемент добавлен", "success");
        this.$store.dispatch("setShowModal", null);
        this.getSubClassifierById({
          page: 0,
          size: 20,
        });
      });
    },

    async getSubClassifierById(pageOptions) {
      let data = {
        id: this.$route.params.subClassifierId,
        size: pageOptions.size,
        page: pageOptions.page,
      };
      await this.$store.dispatch("getAllDataSubClassifier", data);
    },
  },

  async mounted() {
    await this.getSubClassifierById({ size: 20, page: 0 });
    this.loadData = false;
  },
};
</script>

<style scoped></style>
