<template>
  <div>
    <h2>Области</h2>

    <el-table stripe border :data="areaList.content" style="width: 100%">
      <el-table-column prop="name" label="Наименование"> </el-table-column>
      <el-table-column width="450" label="">
        <template slot-scope="raw">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="float: right"
                @click="openEditModal(raw.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                size="mini"
                @click="deleteItem(raw.row)"
                style="float: right; margin-right: 10px"
                type="danger"
                >Удалить</el-button
              >
              <el-button
                size="mini"
                @click="viewItem(raw.row)"
                style="float: right"
                type="primary"
                >Просмотреть</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="areaList.size"
      :current-page.sync="areaList.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="areaList.totalElements"
    >
    </el-pagination>

    <el-dialog title="Классификатор отходов" :visible.sync="modalShow">
      <el-row :gutter="10">
        <el-col :span="20">
          <el-input v-model="area.name"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button @click="save" type="primary">Сохранить</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "areasSubClassifier",
  mixins: [notification],
  data() {
    return {
      searchPole: "",
      pageOptions: {
        size: 20,
        page: 0,
      },
      area: {
        id: null,
        name: "",
      },
      modalShow: false,
    };
  },

  computed: {
    ...mapGetters({
      areaList: "GETLISTALLAREAS",
    }),

    getPaginationOptions: {
      get() {
        return {
          page: this.areaList.number,
          size: this.areaList.size,
        };
      },
    },
  },

  methods: {
    handleSizeChange(val) {
      this.getAreaList({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getAreaList({ page: val - 1, size: this.areaList.size });
    },

    openEditModal(item) {
      this.area = item;
      this.modalShow = true;
    },

    deleteItem(item) {
      this.confirm(
        "Удалить область",
        "Вы уверены что хотите удалить область",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store.dispatch("deleteArea", item).then((response) => {
          console.log(response.data);
          if (response.data === "DELETED") {
            this.notification("Успешно", "Область удалена", "success");
            this.getAreaList(this.getPaginationOptions);
          }
        });
      });
    },

    viewItem(item) {
      this.$router.push(item.id + "/districts");
      console.log(item);
    },

    search() {},

    add() {
      this.modalShow = true;
    },

    save() {
      if (this.area.id === null) {
        this.$store.dispatch("createArea", this.area).then((response) => {
          if (response.data.id !== undefined) {
            this.notification("Успешно", "Область добавлена", "success");
            this.getAreaList(this.getPaginationOptions);
            this.modalShow = false;
          }
        });
      } else {
        this.$store.dispatch("updateArea", this.area).then((response) => {
          if (response.data.id !== undefined) {
            this.notification("Успешно", "Область обновлена", "success");
            this.getAreaList(this.getPaginationOptions);
            this.modalShow = false;
          }
        });
      }
    },

    getAreaList(data) {
      this.$store.dispatch("getListAllAreas", data);
    },
  },

  mounted() {
    this.getAreaList(this.pageOptions);
  },
};
</script>

<style scoped></style>
